/// <reference path="../utilities/build" />

html, body {
    height: 100%;
}

.standard-layout {
    background: linear-gradient($white 80%, $gallery);
    background-attachment: fixed;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;

    &__body {
        @extend .container-fluid;
        padding-right: $layout-padding-right;
        padding-left: $layout-padding-left;
    }
}

.custom-nav {
    padding-right: $layout-padding-right;
    padding-left: $layout-padding-left;
}
