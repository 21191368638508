/// <reference path="../utilities/build" />

.notifications-container {
    margin-bottom: 2rem;

    .alert {
        background-position: 15px center;
        background-repeat: no-repeat;
        background-size: 1.5rem;
        padding-left: 50px;

        &-success {
            background-image: url("/images/alert-success.svg") !important;
        }

        &-danger {
            background-image: url("/images/alert-error.svg") !important;
        }

        &-info {
            background-image: url("/images/alert-info.svg") !important;
        }

        &-warning {
            background-image: url("/images/alert-warning.svg") !important;
        }
    }

    .notification-text {
        ul {
            margin-bottom: 0;
        }
    }
}
