/// <reference path="../utilities/build" />

.header {
    @extend .container-fluid;
    display: flex;
    padding-top: $layout-padding-top;
    padding-right: $layout-padding-right;
    padding-bottom: $layout-padding-botton;
    padding-left: $layout-padding-left;

    &__logo {
        flex-grow: 1;

        img {
            width: 365px;
        }
    }

    &__contents {
        align-items: center;
        display: flex;
        white-space: nowrap;

        .header-welcome {
            color: $gray;
            margin-right: 24px;
        }

        .header-link {
            &:not(:last-child) {
                margin-right: 24px;
            }

            img {
                width: 22px;
            }

            a, .btn {
                font-weight: $font-weight-bold;
                margin-left: 8px;
            }
        }
    }
}
