/// <reference path="../utilities/build" />

$rag-statuses: (
  "red": $red,
  "amber": $amber,
  "green": $green
);

.rag-status {
    border-radius: 50%;
    color: $white;
    cursor: default;
    display: inline-block;
    line-height: 30px;
    height: 30px;
    text-align: center;
    width: 30px;
}

@each $rag-status, $rag-colour in $rag-statuses {
    .rag-status--#{$rag-status} {
        background: $rag-colour;
    }
}
