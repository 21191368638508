/// <reference path="../utilities/build" />

.btn {
    text-transform: uppercase;

    &.btn-primary {
        color: $white !important;
    }
}

.btn-link {
    padding: 0;
    text-transform: none;

    &:focus {
        box-shadow: none;
        outline: 0;
    }
}

.btn-close {
    &:focus {
        box-shadow: none;
        outline: 0;
    }
}

.btn-icon {
    padding: 0;
    outline: none;
}
