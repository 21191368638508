/// <reference path="../utilities/build" />

.user-management {
    &__summary {
        @include panel;

        .btn {
            padding: 0.375rem;
            width: 185px;
        }

        .filter {
            padding: 0;
        }
    }

    &__body {
        .header-item, .line-item {
            &__actions {
                width: 200px;

                button, a {
                    border-width: 2px;
                    padding: 8px 16px;
                    text-transform: none;

                    &:hover {
                        color: $white;
                    }

                    &:focus {
                        box-shadow: none;
                    }

                    .svg-inline--fa {
                        font-size: $font-size-base;
                        margin: 0 4px 0 0;
                    }
                }
            }
        }
    }
}
