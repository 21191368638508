/// <reference path="../utilities/build" />

footer {
    color: $gray;
    margin-top: 60px;
    margin-bottom: $default-margin-bottom;
    text-align: center;

    img {
        margin-bottom: $default-margin-bottom;
        max-height: 24px;
    }

    p {
        margin: 0;
    }
}
