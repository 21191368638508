/// <reference path="../utilities/build" />

.svg-inline--fa {
    font-size: 18px;
    margin-left: 4px;

    &:focus {
        outline: none;
    }

    &.fa-exclamation-triangle {
        color: $scarlet;
    }

    &.fa-info-circle {
        color: $denim;
    }
}

.tooltip-arrow {
    display: none !important;
}

.tooltip-inner {
    border: 1px solid $silver;
    box-shadow: $default-drop-shadow;
    text-align: left;

    h3 {
        text-transform: uppercase;
        margin: 0px;
        font-size: 14px !important;

        &.warning {
            color: $scarlet;
        }

        &.information {
            color: $denim;
        }
    }

    p {
        margin: 0px;
    }
}
