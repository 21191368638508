/// <reference path="../utilities/build" />

.reconciliation-reports {
    &__summary {
        display: flex;
        gap: 2rem;

        .reconciliation-reports-summary {
            &__filter, &__actions {
                @extend .filter;
                align-items: center;
                flex-basis: 100%;
                flex: 1;
                gap: 2rem;

                strong {
                    margin-right: 1.25rem;
                    text-transform: uppercase;
                }
            }
        }
    }

    &__body {
        .line-item {
            &__actions {
                button, a {
                    border-width: 2px;
                    padding: 8px 16px;
                    text-transform: none;

                    &:hover {
                        color: $white;
                    }

                    &:focus {
                        box-shadow: none;
                    }

                    .svg-inline--fa {
                        font-size: $font-size-base;
                        margin: 0 4px 0 0;
                    }
                }
            }

            &__removeArrows {
                /* Chrome, Safari, Edge, Opera */
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
                /* Firefox */
                input[type=number] {
                    -moz-appearance: textfield;
                }
            }
        }
    }

    &__actions {
        text-align: right;
    }
}
