/// <reference path="../utilities/build" />

.export-batch {
    display: block;
}

.dashboard {

    &__actions {
        width: 160px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .action {
            width: 30px;
            margin-right: 2px;

            &__invoice {
                margin-left: 30px;
            }

            &__delete-invoice {
                margin-left: 35px;
            }
        }

        img {
            width: 30px;
        }
    }
}
