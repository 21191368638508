/// <reference path="../utilities/build" />

.toolbar {
    align-items: center;
    color: $primary;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.625rem;

    &__paging-size {
        display: flex;
        align-items: center;

        label {
            margin-right: 1.25rem;
        }

        select {
            width: 180px;
        }
    }

    &__pagination {
        .pagination {
            display: flex;
            list-style: none;
            margin: 0;
            padding-left: 0;

            li {
                a, span {
                    padding: 2px 8px;
                }

                &:first-child {
                    a, span {
                        padding-left: 0;
                    }
                }

                &:last-child {
                    a, span {
                        padding-right: 0;
                    }
                }

                &.active {
                    font-weight: $font-weight-bold;
                }

                &.disabled {
                    color: $silver;
                    cursor: not-allowed;
                }
            }
        }
    }
}
