/// <reference path="../utilities/build" />

.page-title {
    margin-bottom: 2.5rem;

    h1 {
        margin: 0;
    }

    h1 + p {
        margin-top: 1.25rem;
    }
}
