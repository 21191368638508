/// <reference path="../utilities/build" />

.purchase-order-details {
    &__summary {
        background: $concrete;
        border-radius: $default-border-radius;
        display: flex;
        font-size: $font-size-lg;
        justify-content: space-between;
        margin-bottom: 1.25rem;
        padding: 1.875rem 3.75rem;

        strong {
            text-transform: uppercase;
            margin-right: 1.25rem;
        }
    }

    &__body {
        .line-item {
            &__invoice-number {
                font-weight: $font-weight-bold;
                width: 130px;
            }

            &__profit-centre {
                width: 275px;
            }

            &__tsi-code {
                width: 100px;
            }

            &__override-actions {
                button, a {
                    color: $primary;
                    font-size: $font-size-sm;
                    padding: 2px;
                    text-transform: none;

                    &:focus {
                        box-shadow: none;
                    }

                    .svg-inline--fa {
                        font-size: $font-size-base;
                        margin: 0 2px 0 0;
                    }
                }
            }
        }
    }

    &__actions {
        text-align: right;
    }
}
