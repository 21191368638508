.accordion-button::after {
    content: url("/images/caret-up.png");
    position: absolute;
    margin-left: 3px;
    margin-top: -3px;
}

.accordion-button.collapsed::after {
    content: url("/images/caret-down.png");
}
