/// <reference path="../../../wwwroot/lib/twitter-bootstrap/scss/_functions.scss" />

/**
 * Default variables.
 */

// Colours
$deep-sea-green: #0a4a60;
$tango: #f17522;
$black: #000000;
$tuatara: #393937;
$scarlet: #f21C0a;
$white: #ffffff;
$sea-buckthorn: #faa82a;
$japanese-laurel: #009e01;
$denim: #1083CB;

// Grays
$concrete: #f2f2f2;
$gallery: #eaeaea;
$gray: #808080;
$silver: #c6c6c6;

// RAG colours
$red: $scarlet;
$amber: $sea-buckthorn;
$green: $japanese-laurel;

// Text
$text-color: $black;

// Theme colours
$primary: $tango;
$secondary: $black;

// Defaults
$default-border-radius: 6px;
$default-drop-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.15);
$default-margin-bottom: 30px;

/**
 * Global styles.
 */
$body-bg: $white;
$body-color: $text-color;
$layout-padding-top: 20px;
$layout-padding-right: 40px;
$layout-padding-botton: 20px;
$layout-padding-left: 40px;

/**
 * Links.
 */
$link-shade-percentage: 20%;
$link-decoration: none;
$link-hover-decoration: underline;
$link-secondary-color: $secondary;
$link-secondary-hover-color: darken($link-secondary-color, $link-shade-percentage);

/**
 * Typography.
 */
$font-family: "Poppins", sans-serif;
$font-family-base: $font-family;
$font-family-sans-serif: $font-family;
$font-family-serif: $font-family;

// Default monospace fonts for <code>, <kbd>, and <pre>.
$font-family-monospace: $font-family;

$font-size-base: 0.875rem; // 14px
$font-size-sm: 0.75rem; // 12px
$font-size-lg: 1rem; // 16px

$h1-font-size: 1.75rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.25rem;
$h4-font-size: 1.125rem;
$h5-font-size: 1rem;
$h6-font-size: 0.875rem;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-bolder: 700;

$headings-color: $primary;
$headings-font-weight: $font-weight-bolder;
$headings-margin-bottom: 2.5rem;

/**
 * Buttons.
 */
$btn-border-radius: 1.5rem;
$btn-font-weight: $font-weight-bold;
$btn-padding-y: 0.875rem;
$btn-padding-x: 1.5rem;

/**
 * Breadcrumbs.
*/
$breadcrumb-separator: "/";
$breadcrumb-padding-y: 0.625rem;
$breadcrumb-padding-x: 0;

/**
 * Labels
 */
$form-label-font-size: $font-size-sm;
$form-label-font-weight: $font-weight-normal;

/**
 * Forms.
*/
$input-padding-y: 0.875rem;
$input-padding-x: 1.875rem;

/**
 * Grid.
 */
$grid-gutter-width: 0rem;

$container-max-widths: (
    "sm": 540px,
    "md": 720px,
    "lg": 960px,
    "xl": 1250px
);

/**
 * Navbar.
 */
$navbar-padding-y: 0.125rem;
$navbar-light-color: $text-color;
$navbar-light-hover-color: rgba($text-color, 0.7);
$navbar-light-active-color: $primary;

/**
 * Dropdowns.
 */
$dropdown-border-radius: 0;
$dropdown-border-color: $gallery;
$dropdown-divider-bg: $silver;

$dropdown-link-active-color: $text-color;
$dropdown-link-active-bg: rgba($primary, 0.05);

/**
 * Tooltip.
 */
$tooltip-bg: $white;
$tooltip-border-radius: $default-border-radius;
$tooltip-color: $black;
$tooltip-font-size: 13px;
$tooltip-max-width: 275px;
$tooltip-opacity: 1;

$tooltip-padding-y: .5rem;
$tooltip-padding-x: .75rem;
