/// <reference path="../utilities/build" />

@mixin panel($bg-color: $concrete) {
    background-color: $bg-color;
    border-radius: $default-border-radius;
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    margin-bottom: 1.875rem;
    padding: 1.5rem;

    .form-group {
        margin-bottom: 0;
    }

    .btn, .form-control {
        padding: 0.375rem;
        /*width: 185px;*/
    }

    .form-control {
        padding-left: 1rem;

        &::placeholder {
            color: $text-color;
        }
    }
}

@mixin infobox($bg-color: $white) {
    background-color: $bg-color;
    box-shadow: $default-drop-shadow;
    border-radius: $default-border-radius;
}

@mixin infobox--with-hover($bg-color: $white) {
    @include infobox($bg-color);
    border: 1px solid transparent;

    &:hover {
        border-color: $primary;
    }
}
