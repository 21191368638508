/// <reference path="../utilities/build" />

.login-container {
    padding: 30px 20px;

    .login-header {
        text-align: center;
        margin-bottom: 28px;

        h1 {
            color: $primary;
            font-size: $h4-font-size;
            text-transform: uppercase;
        }

        img {
            margin-bottom: 30px;
            max-height: 40px;
        }
    }

    .login-contents {
        .login-form {
            margin-bottom: 22px;

            label {
                display: none;
            }

            .form-checkbox {
                padding-left: 2rem;
            }
        }

        .login-actions {
            text-align: center;

            button {
                width: 100%;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .login-layout {
        background: linear-gradient($white, $gallery);
        background-attachment: fixed;
        height: 100%;
        display: grid;
        grid-template-rows: 1fr auto;

        .login-container {
            @include infobox;
            margin: 140px auto auto;
            padding: 45px 40px;
            width: 420px;
        }
    }
}
