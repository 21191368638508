/// <reference path="../utilities/build" />

table {
    margin-bottom: 50px;
    width: 100%;

    thead tr {
        border-bottom: 2px solid $primary;
        color: $primary;
        text-transform: uppercase;
        vertical-align: top;
    }

    thead th, tbody td {
        padding: 5px 5px;
    }
}

.line-item-row {
    &--odd + &--even, &--even + &--odd {
        border-top: 1px solid $silver;
    }

    &:last-child {
        border-bottom: 1px solid $silver;
    }

    &--odd {
        background: $concrete;
    }
}

.line-item {
    &__quantity {
        width: 60px;
        text-align: center;
    }

    &__center {
        text-align: center;
    }

    &__date {
    }

    &__empty {
        padding: 20px 10px;
    }

    &__actions {
        text-align: center;

        img {
            width: 30px;
        }
    }
}

$width: 50;

@while $width <= 350 {
    .column-#{$width} {
        width: $width + px;
    }

    $width: $width + 10;
}
