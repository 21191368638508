/// <reference path="../utilities/build" />

.invoice-details {
    &__body {
        .line-item {
            &__tsi-code {
                font-weight: $font-weight-bold;
                width: 100px;
            }
        }
        .original-po-number {
            text-decoration: line-through;
        }
        .override-po-number {
            font-weight: $font-weight-bold;
        }
    }

    &__actions {
        text-align: right;
    }
}
