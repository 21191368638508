/// <reference path="../utilities/build" />

.access-denied-container {
    text-align: center;

    h2 {
        color: $gallery;
        cursor: default;
        font-size: 16rem;
        margin-bottom: 0;
    }

    p {
        margin-bottom: 0.5rem;
    }

    .btn {
        margin-top: 1.6rem;
    }
}
