/// <reference path="../utilities/build" />

.system-configuration-container {
    .standard-form {
        .form-control {
            width: 120px;
        }

        .input-group {
            width: auto;
        }

        .input-group-text {
            background: none;
            border: none;
            font-size: $font-size-lg;
            font-weight: $font-weight-bold;
            padding: .875rem .5rem;
        }

        .col-4 {
            align-items: flex-start;
        }
    }
}
