/// <reference path="../utilities/build" />

.modal {
    .modal-content {
        border: 0;

        .modal-header {
            border-bottom: 0;
            padding-bottom: 0;

            .btn-close {
                color: $primary;
            }
        }

        .modal-body {
            padding: 0.5rem 4.5rem;

            .modal-title {
                color: $black;
                margin-bottom: 0.5rem;
                text-align: center;
            }

            .form-group:last-child, p:last-child {
                margin-bottom: 0;
            }
        }

        .modal-footer {
            border-top: 0;
            justify-content: space-between;
            padding: 0.5rem 4.5rem 2rem;

            .btn {
                padding: 0.6rem $btn-padding-x;
                width: 47%;
            }
        }
    }
}
