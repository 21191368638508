/// <reference path="../utilities/build" />

select {
    // Replace browser default down arrow with background image.
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("/images/arrow-down.png");
    background-repeat: no-repeat, repeat;
    background-position: right .5rem top 50%, 0 0;
    border-radius: $border-radius;
    border-color: $silver;
    color: $gray;
    outline: none;
    padding: .2rem 1.75rem .2rem .5rem;

    &:hover {
        background-image: url("/images/arrow-down--hover.png");
    }

    &:focus {
        background-image: url("/images/arrow-down.png");
        border-color: $primary;
    }

    // Adjust for rounded corners
    &.form-control {
        background-position: right .75rem top 50%, 0 0;
    }
}

input {
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $gray;
        opacity: 1; /* Firefox */
    }
}

.field-validation-error {
    color: $scarlet;
    display: block;
    margin: 4px 0;
    text-align: center;
}

.form-checkbox {
    cursor: pointer;
    display: inline-block;
    line-height: 23px;
    /*padding-left: 2rem;*/
    position: relative;
    user-select: none;

    &__label {
        display: inline-block !important;
        font-weight: $font-weight-normal;
        margin: 0 !important;
    }

    &__input {
        cursor: pointer;
        height: 0;
        opacity: 0;
        position: absolute;
        width: 0;
    }

    &__icon {
        background-color: $white;
        border: 1px solid $silver;
        height: 23px;
        left: 0;
        position: absolute;
        top: 0;
        width: 23px;

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 7px;
            top: 2px;
            width: 7px;
            height: 14px;
            border: solid $primary;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
        }
    }

    &:hover input ~ .form-checkbox__icon {
        /*border-color: $brand-primary;*/
    }

    input:checked ~ .form-checkbox__icon {
        border-color: $primary;
    }

    input:checked ~ .form-checkbox__icon:after {
        display: block;
    }

    input:disabled ~ .form-checkbox__icon {
        background: $gallery;
        border-color: #cacaca;
        cursor: not-allowed;
    }

    input:disabled ~ .form-checkbox__icon:after {
        border-color: #cacaca;
    }
}

.form-group {
    margin-bottom: 1.25rem;

    .form-control {
        border-color: transparent;
        box-shadow: inset 0px 5px 15px -5px $silver;
        border-radius: 1.5rem !important;
        padding-left: 30px;

        &:focus {
            border: 1px solid $primary;
            box-shadow: inset 0px 5px 15px -5px #cacaca;
        }

        &:disabled,
        &[readonly] {
            cursor: not-allowed;
        }
    }

    .form-control-icon {
        display: flex;
        position: relative;
        align-items: center;

        img {
            position: absolute;
            margin-left: 20px;
        }

        input {
            padding-left: 50px !important;
        }
    }
}

.form-actions {
    float: right !important;
    margin-bottom: 1.25rem;

    .btn {
        margin-left: 0.5rem;
        width: 185px;
    }
}

.standard-form {
    margin-bottom: 2.5rem;

    label {
        font-weight: $font-weight-bold;
        text-transform: uppercase;
    }

    .col {
        align-items: center;
        display: flex;
    }

    .col-4 {
        flex-direction: column;
    }
}

.readonly-form {
    @extend .standard-form;

    .col-4 {
        flex-direction: row;
    }

    .form-group {
        margin-bottom: 2rem;
    }
}
