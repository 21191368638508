/// <reference path="../utilities/build" />

.dropdown-toggle {
    background-image: url("/images/arrow-down.png");
    background-repeat: no-repeat, repeat;
    background-position: right .6rem top 50%, 0 0;
    padding-right: 1.875rem !important;

    &:hover {
        background-image: url("/images/arrow-down--hover.png");
    }

    &::after {
        display: none;
    }
}
