/// <reference path="../utilities/build" />

.filter {
    @extend .form-group;
    @include panel;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.875rem;
    padding: 1.5rem;

    .btn, .form-control {
        padding: 0.375rem;
        width: 185px;
    }

    .form-control {
        padding-left: 1rem;

        &::placeholder {
            color: $text-color;
        }
    }
}
