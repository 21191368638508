/// <reference path="../utilities/build" />

.navbar {
    border-bottom: 1px solid $gallery;
    border-top: 1px solid $gallery;
    margin-bottom: 2.5rem;
    font-weight: $font-weight-bold;

    .navbar-nav {
        .nav-item {
            &:first-child > .nav-link {
                padding-left: 0;
            }

            &:last-child > .nav-link {
                padding-right: 0;
            }

            .pending-approved {
                display: block;
                padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
                padding-left: 0.5em;
                padding-right: 0.5em;
                font-weight: 700;
                background-color: $concrete;
            }
        }
    }
}
